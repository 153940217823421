import style from "./style.module.scss";

const SessionEx = () => {
  const url = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_LMS_URL : "http://localhost:3000";

  return (
    <div className={style.sessionExp}>
      <div className={style.continer}>
        <div className={style.icon}>
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 8V12L14.5 14.5" stroke="#ff5e1a" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#ff5e1a" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>

        </div>
        <div className="text-center mb-4 mt-2">
          <h3 className="mt-3 mb-2">Session expirée</h3>
          <p className="text-muted mb-4">
            Votre session a expiré. Veuillez vous reconnecter !
          </p>

          <a className={style.btn} href={url}>
            <div></div>
            Reconnecter
          </a>
        </div>
      </div>
    </div>
  );
};

export default SessionEx;
