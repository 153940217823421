import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getLoggedInUser, isUserAuthenticated } from './helpers/authUtils';
import NotFound from './pages/NotFound';
import { Analysis } from './pages/StratEdge/Analysis';
import { Atterissage } from './pages/StratEdge/Atterissage';
import { BsTimeline } from './pages/StratEdge/BsTimeline';
import { CompetitorAnalysis } from './pages/StratEdge/CompetitorAnalysis';
import { MarketAnalysis } from './pages/StratEdge/MarketAnalysis';
import { ProductAnalysis } from './pages/StratEdge/ProductAnalysis';
import { SavedScenarios } from './pages/StratEdge/SavedScenarios';
import { StrategicDecisions } from './pages/StratEdge/StrategicDecisions';
import { TestP } from './pages/StratEdge/TestP';
import { StratEdgeAnalysis } from './pages/StratEdge/admin/StratEdgeAnalysis';
import { StratEdgeCompetitorAnalysis } from './pages/StratEdge/admin/StratEdgeCompetitorAnalysis';
import { StratEdgeMarketAnalysis } from './pages/StratEdge/admin/StratEdgeMarketAnalysis';
import { StratEdgeProductAnalysis } from './pages/StratEdge/admin/StratEdgeProductAnalysis';
import { StratEdgeStrategicDecisions } from './pages/StratEdge/admin/StratEdgeStrategicDecisions';
import Config from './pages/businessGame/config';

// import Drag from './pages/test-d/drag';
import LoaderPage from './pages/LoaderPage';
import SessionEx from './pages/SessionEx';



const PrivateRoute = ({ component: Component, roles, ...rest }) => (


    <Route
        {...rest}
        render={(props) => {
            // const isAuthTokenValid = isUserAuthenticated();
            // if (!isAuthTokenValid) {
            //     return (
            //         <Redirect from='*' to='/404' />

            //     );
            // }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role.name) === -1) {
                // role not authorised so redirect to home page
                return <Redirect from='*' to='/404' />

            }

            return <Component {...props} />;
        }}
    />
);

const routes = [
    { path: '/loaderPage', name: 'loaderPage', component: LoaderPage, route: Route },
    { path: '/session-expired', name: 'sessionExpired', component: SessionEx, route: Route },

    // { path: '/login', name: 'Login', component: Login, route: Route },
    // { path: '/logout', name: 'Logout', component: Logout, route: Route },
    // {
    //   path: '/forget-password',
    //   name: 'Forget Password',
    //   component: ForgetPassword,
    //   route: Route,
    // },
    { path: '/not-found', name: 'NotFound', component: NotFound, route: Route },
    {
        path: '/',
        name: 'Atterissage',
        component: Atterissage,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'Layout',
    },

    {
        path: '/bsn',
        name: 'StudentManagement',
        component: TestP,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/analysis',
        name: 'Analysis',
        component: Analysis,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'BusinessGameLayout',
    },

    {
        path: '/strategic-decisions',
        name: 'StrategicDecisions',
        component: StrategicDecisions,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/saved-scenarios',
        name: 'StrategicDecisions',
        component: SavedScenarios,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    /*  END Participant Routes */

    /*  Moderator Routes */

    {
        path: '/moderator/analysis',
        name: 'StratEdgeAnalysis',
        component: StratEdgeAnalysis,
        route: PrivateRoute,
        roles: ['Moderator'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/moderator/timeline-tours',
        name: 'BsTimeline',
        component: BsTimeline,
        route: PrivateRoute,
        roles: ['Moderator', 'Participant'],
        title: '',
        layout: 'Layout',
    },
    {
        path: '/simulations',
        name: 'Simulations',
        component: TestP,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/moderator/product-analysis',
        name: 'StratEdgeProductAnalysis',
        component: StratEdgeProductAnalysis,
        route: PrivateRoute,
        roles: ['Moderator'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/product-analysis',
        name: 'ProductAnalysis',
        component: ProductAnalysis,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/competitor-analysis',
        name: 'CompetitorAnalysis',
        component: CompetitorAnalysis,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/moderator/competitor-analysis',
        name: 'StratEdgeCompetitorAnalysis',
        component: StratEdgeCompetitorAnalysis,
        route: PrivateRoute,
        roles: ['Moderator'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/moderator/market-analysis',
        name: 'StratEdgeMarketAnalysis',
        component: StratEdgeMarketAnalysis,
        route: PrivateRoute,
        roles: ['Moderator'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/market-analysis',
        name: 'MarketAnalysis',
        component: MarketAnalysis,
        route: PrivateRoute,
        roles: ['Participant'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/moderator/strategic-decisions',
        name: 'StratEdgeStrategicDecisions',
        component: StratEdgeStrategicDecisions,
        route: PrivateRoute,
        roles: ['Moderator'],
        title: '',
        layout: 'BusinessGameLayout',
    },
    {
        path: '/moderator/business-game/:gameConfigurationId',
        name: 'businessGame',
        component: Config,
        route: PrivateRoute,
        roles: ['Moderator'],
        title: '',
        layout: 'ModuleConfig',
    },



    /*  Moderator Routes */
    // {
    //   path: '/',
    //   exact: true,
    //   component: () =>
    //     getLoggedInUser().role.id === 2 ? (
    //       <Redirect to="/moderator/modules" />
    //     ) : (
    //       <Redirect to="/dashboard" />
    //     ),
    //   route: PrivateRoute,
    // },
];

export { PrivateRoute, routes };