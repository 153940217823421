import axios from 'axios';
import { authHeader } from './authUtils';
import { Cookies } from 'react-cookie';
import { handleTokenRefresh } from './refreshToken';

const baseURL = process.env.REACT_APP_API_URL;
const cookies = new Cookies();
const httpClient = axios.create({});


httpClient.interceptors.request.use(
   async (config) => {
      const token = cookies.get('token');
      if (token) {
         config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
   },
   (error) => {
      return Promise.reject(error);
   }
);

httpClient.interceptors.response.use(
   (response) => {
      return response;
   },
   async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401) {
         originalRequest._retry = true;
         try {
            const updatedRequest = await handleTokenRefresh(originalRequest);
            return httpClient(updatedRequest);
         } catch (refreshError) {
            return Promise.reject(refreshError);
         }
      }

      const message = error.response?.data?.message || error.message;
      if (message === "Expired or invalid JWT token" || message === 'JWT Token does not begin with Bearer String') {
         Object.keys(cookies.getAll()).forEach(cookie => cookies.remove(cookie));
         window.location.assign(`${window.location.origin}/session-expired`);
      }

      return Promise.reject(error);
   }
);
/**
*
* @param {string} url
* @param {object} data
*/

const httpClient_post = (url, data, baseURL_ = baseURL) => {
   httpClient.defaults.headers.common = authHeader();
   return httpClient.post(`${baseURL_}${url}`, data);
}

/**
 *
 * @param {string} url
 * @param config
 */
const httpClient_get = (url, config = {}) => {
   httpClient.defaults.headers.common = authHeader();
   return httpClient.get(`${baseURL}${url}`, config);
}

/**
 *
 * @param {string} url
 * @param {object} data
 */
const httpClient_delete = (url, data) => {
   return httpClient.delete(`${baseURL}${url}`, data);
}

/**
 *
 * @param {string} url
 * @param {object} data
 */
const httpClient_put = (url, data) => {

   return httpClient.put(`${baseURL}${url}`, data);
}




export {
   httpClient,
   httpClient_post,
   httpClient_get,
   httpClient_delete,
   httpClient_put

}
