import axios from 'axios';
import { Cookies } from 'react-cookie';

const baseURL = process.env.REACT_APP_API_URL;
const cookies = new Cookies();

export const handleTokenRefresh = async (originalRequest) => {
    try {
        const refreshToken = cookies.get('refreshToken');
        console.log("🚀 ~ handleTokenRefresh ~ refreshToken:--->>>>", refreshToken)
        const response = await axios.post(`${baseURL}/user/refresh_token`, {
            refresh_token: refreshToken
        });
        const { access_token, refresh_token } = response.data;
        cookies.set('token', access_token);
        cookies.set('refreshToken', refresh_token);
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return originalRequest;
    } catch (refreshError) {
        // Object.keys(cookies.getAll()).forEach(cookie => cookies.remove(cookie));
        window.location.assign(`${window.location.origin}/session-expired`);
        return Promise.reject(refreshError);
    }
};